import Grid from "@mui/material/Grid";
import legendIntervensi from "../../../../../assets/images/icons/icon_stunting.png";
import legendKonfirmasi from "../../../../../assets/images/icons/stunting_terkonfirmasi.png";
const legend = () => {
  return (
    <div className="legend-div">
      <h3>Legenda</h3>
      <Grid container rowSpacing={1} marginTop={1} borderRadius={50}>
          <Grid item xs={12} md={3} lg={3}>
            <img src={legendKonfirmasi} alt="" width="25pt" />
          </Grid>
          <Grid item xs={12} md={9} lg={9} >
            <small>Kasus Baru</small>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <img src={legendIntervensi} alt="" width="25pt" />
          </Grid>
          <Grid item xs={12} md={9} lg={9} >
            <small>Dalam Intervensi</small>
          </Grid>
      </Grid>
    </div>
  );
};

export default legend;
