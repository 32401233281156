// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import datas from "./data/chicago-parks.json";
import { useListStunting } from "../stores";
import intervensiMarker from "../../../../assets/images/icons/icon_stunting.png";
import kasusMarker from "../../../../assets/images/icons/stunting_terkonfirmasi.png";
import sembuhMarker from "../../../../assets/images/icons/stunting_sembuh.png";
import Legend from "./legend"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;
function stuntingMap() {
  const bounds = [[ 124.588431, 1.366586,],[ 125.126096, 1.730751,]]
  const mapContainer = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup());
  // const [lng, setLng] = useState(124.784414);
  // const [lat, setLat] = useState(1.525244);
  // const [Zoom, setZoom] = useState(10.7);
  const [stateList, actions] = useListStunting();
  const markers = [{url: kasusMarker ,id:'kasusMarker'},{url: intervensiMarker, id:'intervensiMarker'},{url: sembuhMarker, id:'sembuhMarker'}]
  console.log(stateList);
  useEffect(() => {
    actions.loadListStunting();
    if (stateList.data.length != 0 || null){
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [124.824432, 1.5371884],
      zoom: 11,
      attributionControl: false,
      maxBounds: bounds,
      maxZoom: 20,
      minZoom: 11,
    });
    map.on("load", () => {
      // Add an image to use as a custom marker
      markers.forEach(img => {
          map.loadImage(img.url, function(error, res) {
            map.addImage(img.id, res)
          })
      })

          // Add a GeoJSON source with multiple points
          map.addSource("points", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: stateList.data,
            },
          });
          // Add a symbol layer
          map.addLayer({
            id: "points",
            type: "symbol",
            source: "points",
            layout: {
              'icon-image': [
                'match',
                [ 'get', 'status' ], // type corresponds to the field name you are keying off of
                [ 'KASUS BARU' ],
                'kasusMarker',
                [ 'DALAM INTERVENSI' ],
                'intervensiMarker',
                'custom-marker' // fallback icon
              ],
              // get the title name from the source's "title" property
              "text-field": ["get", "title"],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 1.25],
              "text-anchor": "top",
              "icon-allow-overlap": true,
            },
          });

          map.on('click', 'points', (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const propHeader = Object.keys(e.features[0].properties);
            const propValue = Object.values(e.features[0].properties);
            const dataAssign = Object.assign(e.features[0].properties);
            console.log(propHeader);
            console.log(propValue);
            console.log(dataAssign);
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            
            new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(`
              <div class="table-wrapper">
                <h2>${e.features[0].properties.nama}</h2>
                  <div class="grid-container">
                    ${propHeader.map((featurePropItem) => `
                      <div class="grid-item">
                          ${featurePropItem}   :  ${e.features[0].properties[featurePropItem]}
                      </div>
                    `)
                    .join("")}
                  </div>
              </div>`)
            .addTo(map);
            });
             
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'places', () => {
            map.getCanvas().style.cursor = 'pointer';
            });
             
            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'places', () => {
            map.getCanvas().style.cursor = '';
            });
    });
    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    // Clean up on unmount
    return () => map.remove();
  }},[stateList.data.length]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={1.5} px={1.5} pb={1.5}>
        <div ref={mapContainer} className="map-container">
          
          <Legend/>
        </div>
      </MDBox>
    </Card>
  );
}

export default stuntingMap;
