import axios from 'axios';

const baseApi = `https://covid19.manadokota.go.id/sikesehatan/`;
const instance = axios.create({
  baseURL: baseApi,
});

// const baseApi = `https://pokeapi.co/api/v2/pokemon/`;
// const instance = axios.create({
//   baseURL: baseApi,
// });

export const apiClient = instance;
