/* eslint-disable max-len */
import { apiClient } from '../../../commons/service/apiClient';

export const listStunting = () =>
  apiClient.get(
    `stunting_dataset`,
  );
  
// export const listStunting = () =>
//   apiClient.get(
//     `ditto`,
//   );
  