// import datas from "../components/StuntingMap/data/chicago-parks.json";
import React, { useEffect } from "react";
import { useListStunting } from "../components/stores";

function reportsBarChartData () {
  useEffect(() => {
    const arrLabel = [];
    var arrDatasets = {};
    const [stateList, actions] = useListStunting();
    actions.loadListStunting();
    console.log (stateList,'^^^^^^^^^^^^^^^^^^^');
    return {
      labels: arrLabel,
      datasets: { data: arrDatasets },
    };
  },[])};

  // const datas = chartkelurahan();
  // console.log(datas)
  // datas = stateList

  // for (let i = 0; i < datas.features.length; i++){
  //   var labelsdata = Object.values(datas.features[i].properties);
  //   console.log(labelsdata[6]);
  //   arrLabel.push(labelsdata[6])
  // };

  // arrLabel.forEach(function(i) { arrDatasets[i] = (arrDatasets[i]||0) + 1;});
  // console.log(arrDatasets);
  // console.log(arrLabel,'soklarloop');

  // arrLabel = Object.keys(arrDatasets);
  // arrDatasets = Object.values(arrDatasets);


export default reportsBarChartData;