/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { red } from '@mui/material/colors';
import {useEffect, useState} from 'react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

// MAPBOX
import Map from "layouts/dashboard/components/StuntingMap";
import { useListStunting } from "./components/stores";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;

  const [stateList, actions] = useListStunting();
  // let sembuh = null;
  const [sembuh , setSembuh] = useState(0); 
  const [inter , setInter] = useState(0); 
  const [newCase , setNewCase] = useState(0);
  var [countKel, setCountKel] = useState(0);

  useEffect(() => {
    console.log("isi render")
    if (stateList.data.length === 0) return ; 
    console.log("isi data count",stateList.data);
    stateList.data.forEach((e) => {
      e.properties.status === "SEMBUH" ? setSembuh(sembuh => sembuh +1) : "" ;
      e.properties.status === "DALAM INTERVENSI" ? setInter(inter => inter +1) : "" ;
      e.properties.status === "KASUS BARU" ? setNewCase(newCase => newCase +1) : "" ;
    });

    //untuk chart tiap kelurahan
    var arrLabel = [];
    var arrDatasets = {};
    var datas = stateList.data;
    var labelsdata = [];
    for (let i = 0; i < datas.length; i++){
      if (datas[i].properties.status == "DALAM INTERVENSI" || datas[i].properties.status == "KASUS BARU"){
        labelsdata = Object.values(datas[i].properties);
        console.log(labelsdata[21],);
        arrLabel.push(labelsdata[21])
      }
    };

    arrLabel.forEach(function(i) { arrDatasets[i] = (arrDatasets[i]||0) + 1;});
    const noDupeArrLabel = Object.keys(arrDatasets);
    console.log(arrDatasets,'isidatasetarr')
    console.log(noDupeArrLabel,'isiarrLabel')
    countKel = setCountKel(countKel = {
      labels: noDupeArrLabel,
      datasets: { data: arrDatasets },
    });


  }, [stateList.data.length])

  console.log("isi luar",sembuh)
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="local_hospital"
                title="Kasus Baru"
                count={newCase}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="medications"
                title="Dalam Intervensi"
                count={inter}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="favorite"
                title="Sembuh"
                count={sembuh}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Stunting"
                count={newCase + inter}
                // percentage={{
                //   color: "success",
                //   amount: "",
                //   label: "Just updated",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={12}>
            <Grid item xs={12} md={12} lg={12}>
              <Map />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox mt={5.5}>
          <Grid container spacing={12}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="dark"
                  title="Jumlah Stunting"
                  description="setiap kelurahan"
                  date=" Recently Updated"
                  chart={countKel}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
